<template>
  <div id="app">
    <div class="rootBox">
      <m-header></m-header>
      <div id="content">
        <router-view />
        <m-footer></m-footer>
      </div>
    </div>
  </div>
</template>

<script>
import MHeader from '@/components/header/header'
import MFooter from '@/components/footer/footer'
export default {
  name: 'app',
  components: { MHeader, MFooter },
}
</script>

<style scoped lang="scss">
#app {
  width: 100vw;
  height: 100vh;
}
.rootBox {
  height: 100%;
}
#content {
  height: calc(100% - 72px);
  margin-top: 72px;
  overflow: auto;
  .wrapper {
    width: 1296px;
    margin: 0 auto;
  }
}
</style>
