import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('@/views/login/login')
const Register = () => import('@/views/register/register')
const ForgetPassword = () => import('@/views/forget-password/forget-password')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPassword,
  },
]

const router = new VueRouter({
  routes,
})

export default router
