/**
 * axios 封装
 */
import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import config from '../config'
import storage from './storage'
import download from './download'

const LOGIN_EXPIRE = '登录过期'
const NETWORK_ERROR = '网络请求异常，请稍后重试'

export const ERR_OK = 100200

// 创建 axios 实例对象，添加全局配置
const service = axios.create({
  baseURL: config.baseApi,
  timeout: 10000,
})

// 请求拦截
service.interceptors.request.use(req => {
  isRedirect = true
  const headers = req.headers
  const token = storage.getItem('token')
  if (!headers.Authorization && token) headers.Authorization = token
  return req
})

// 响应拦截
let isRedirect = true

service.interceptors.response.use(
  res => {
    // 拦截下载，如果返回的是二进制文件流，会直接下载
    if (!download(res)) {
      return
    }
    const { code, dataSet, msg, totalNumber } = res.data
    if (code === ERR_OK) {
      if (typeof totalNumber === 'number') {
        return {
          dataSet,
          totalNumber,
        }
      }
      return dataSet
    } else if (code === '100401') {
      Message.error(LOGIN_EXPIRE)
      setTimeout(() => {
        router.push('/login')
      }, 1500)
      return Promise.reject(LOGIN_EXPIRE)
    } else {
      Message.error(msg || NETWORK_ERROR)
      return Promise.reject(msg || NETWORK_ERROR)
    }
  },
  error => {
    const { resultCode, msg } = error.response.data
    if (resultCode === '100401') {
      if (isRedirect) {
        Message.error(msg)
        router.push('/login')
        isRedirect = false
      }
      return Promise.reject(LOGIN_EXPIRE)
    }
    return Promise.reject(LOGIN_EXPIRE)
  },
)

/**
 * 请求核心函数
 * @param options 请求的配置
 * @returns {AxiosPromise}
 */
function request(options) {
  // 在 get 和 post 调用的时候统一传 data 为参数，但是在请求的时候把 get 的请求参数转换为 params
  options.method = options.method || 'get'
  if (options.method.toLowerCase() === 'get') {
    options.params = options.data
  }

  // config.mock 是控制全局接口，options.mock 可以在个别接口里边重新定义 mock 变量
  if (options.mock != null) {
    config.mock = options.mock
  }

  if (config.env === 'prod') {
    service.defaults.baseURL = config.baseApi
  } else {
    service.defaults.baseURL = config.mock ? config.mockApi : config.baseApi
  }
  return service(options)
}

// 添加直接调用的方法：比如 this.$request.get() ......
;['get', 'post', 'put', 'delete', 'patch'].forEach(method => {
  request[method] = (url, data, options) => {
    return request({
      url,
      data,
      method,
      ...options,
    })
  }
})

export default request
