/**
 * 环境配置封装
 */
const env = process.env.VUE_APP_NODE_ENV || 'prod'

const EnvConfig = {
  dev: {
    baseApi: '/api/core-api',
    mockApi: '',
  },
  test: {
    baseApi: '',
    mockApi: '',
  },
  prod: {
    // baseApi: 'https://testrz.lenovo.com.cn/receiptApi/',
    // baseApi: 'https://piaoju.lenovo.com/receiptApi/',
    baseApi: 'https://scf.lenovo.com/core-api/',
    // baseApi: 'https://testm.jr.lenovo.com.cn/coreApi/',
    mockApi: '',
  },
}

export default {
  env,
  mock: false,
  namespace: 'ssq',
  ...EnvConfig[env],
}
