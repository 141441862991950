export const lang = {
  homeOverview: 'Le Yibao',
  firmOverview: 'Electronic signing platform',
  firmReports: 'login',
  firmAppendix: 'register',
  firmIndex: 'PasswordLogin',
  firmAnalysis: 'codeLogin',
  firmNews: 'user name',
  firmOther: 'password',
  forgrtPassword: 'forgrt-password',
  checked: 'I have read and agree',
  checked1: 'Leyi sign service agreement',
  checked2: 'Privacy policy',
  checked3: 'Digital certificate usage protocol',
  login: 'login',
  no: 'No account',
  nowRegister: 'Register now',
  contactUs: 'contact us',
  weekdays: 'weekdays 09:30-18:30',
}
