export const lang = {
  homeOverview: '乐易签',
  firmOverview: '电子签约平台',
  firmReports: '登录',
  firmAppendix: '注册',
  firmIndex: '密码登录',
  firmAnalysis: '验证码登录',
  firmNews: '用户名',
  firmOther: '密码',
  forgrtPassword: '忘记密码',
  checked: '我已阅读并同意',
  checked1: '乐易签服务协议',
  checked2: '隐私政策',
  checked3: '数字证书使用协议',
  login: '登录',
  no: '没有账号',
  nowRegister: '立即注册',
  contactUs: '联系我们',
  weekdays: '工作日 09:30-18:30',
}
