<template>
  <div class="footer">
    <h2>
      联系我们
      <span>（工作日 09:30-18:30）</span>
    </h2>
    <p style="color: #fff; margin-top: 40px">业务咨询：15694788968</p>
    <p style="color: #fff; margin-top: 40px">
      联系地址：北京市海淀区上地西路6号北研大厦G座
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.footer {
  position: relative;
  width: 100%;
  height: 224px;
  box-sizing: border-box;
  background: #333;
  padding-left: 280px;
  padding-top: 48px;
  h2 {
    font-size: 24px;
    color: #fff;
    line-height: 28px;

    span {
      font-size: 16px;
      color: #fff;
      line-height: 26px;
    }
  }
}
</style>
