<template>
  <div class="header">
    <div class="left">
      <span class="title">乐易签</span>
      <span class="feng"></span>
      <span class="dz">电子签约平台</span>
    </div>
    <div class="right">
      <router-link to="/login">
        {{ this.$store._modules.root.state.isregister.login }}
      </router-link>
      <!-- <a style="margin-right: 32px" @click="ljzc">
        {{ this.$store._modules.root.state.isregister.register }}
      </a> -->
      <router-link to="register">
        {{ this.$store._modules.root.state.isregister.register }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { getPhoneCode, getRegister } from './api'
export default {
  data() {
    // 验证是否是正确格式的手机号
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确格式的手机号'))
        }
      }
    }
    // 验证正确的密码格式: 6-16位，有数字和字母
    const passwordTest = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入6-18位并且必须包含数字和字母的密码'))
        }
      }
    }
    return {
      active: 0,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      show: true,
      count: 60,
      timer: null,
      list: [
        { index: 0, des: '中文' },
        { index: '', des: '|' },
        { index: 1, des: 'English' },
      ],
      modal1: false,

      form1: {
        number: '',
        name: '',
        company: '',
        code: '',
        password: '',
      },

      rules1: {
        name: [
          { required: true, message: '请输入您的真实姓名', trigger: 'blur' },
        ],
        number: [{ validator: validatePhone, required: true, trigger: 'blur' }],
        company: [
          { required: true, message: '请输入境内公司全称', trigger: 'blur' },
        ],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        password: [
          { validator: passwordTest, required: true, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    ljzc() {
      this.modal1 = true
    },
    ok() {
      this.dialogFormVisible = true
    },
    cancel() {
      console.log('弹窗关闭')
    },
    async _getCode() {
      if (!this.show || !this.form1.number) {
        return
      }
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        try {
          const params = {
            phoneNumber: this.form1.number,
            templateId: 2,
          }
          await getPhoneCode(params)
          this.$message.success('发送成功，请注意查收')
        } catch (err) {
          console.error(err)
        }
      }
      console.log(111)
    },

    _Register() {
      const params = {
        realName: this.form1.name,
        phone: this.form1.number,
        passWord: this.form1.password,
        companyName: this.form1.company,
        code: this.form1.code,
        email: '',
      }
      this.$refs.form1.validate(async valid => {
        if (valid) {
          try {
            const result = await getRegister(params)
            if (result) {
              window.location.href = result.upUPSignData.value
            }
          } catch (err) {
            console.error(err)
          }
        }
      })
    },
    changeLangEvent() {},
  },
}
</script>

<style scoped lang="scss">
.el-dialog__wrapper {
  top: 19% !important;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  padding: 0 212px;
  line-height: 72px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;

  .left {
    .title {
      width: 96px;
      height: 33px;
      font-size: 32px;
      font-family: FZLTZCHJW--GB1-0, FZLTZCHJW--GB1;
      font-weight: normal;
      color: #147fd2;
    }

    .feng {
      padding: 12px 6px 0px 6px;
      margin-left: 6px;
      border-left: 1px solid #000;
      font-size: 0;
    }

    .dz {
      width: 96px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }

  .right {
    a {
      font-size: 16px;
      color: #333;
      &:first-child {
        margin-right: 32px;
      }
    }
    .router-link-exact-active,
    .router-link-active {
      color: #147fd2;
    }

    .i18n {
      color: #333;
    }
    span.active {
      color: #147fd2;
    }
  }
}
.getCode {
  display: inline-block;
  width: 128px;
  height: 44px;
  background: #147fd2;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  font-weight: 400;
  margin-left: 8px;
  cursor: pointer;
}
.el-form-item {
  margin-left: 36px;
  margin-top: 32px;

  .el-input {
    width: 348px;
    height: 44px;
  }
}
</style>
